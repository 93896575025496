import React from 'react';
import { ListCell } from '../../molecules';
import { Card, CardTitle } from '../../atoms';
import { isEmpty } from '../../../utils/common';
import themes from '../../../themes';

/**
* theme is passed from styled component withTheme HOC.
*/
const ListsWithHeader = ({ theme, listCellOptions, header, mt, textMargin, subTextmargin, padding , isBottomBordered = false }) => {
  const newListCellOptions = listCellOptions.filter((item) => !isEmpty(item))
  console.log('isBottomBordered',isBottomBordered)
  const listCard = () => (
    <div className={`${mt} `}>
      {newListCellOptions.map(({ header, subHeader, onClick, icon, link, type, direction=true }, i) => {
        if (type==='action') {
          return <ListCell onClick={onClick} border={`0.1px solid ${themes.colors.borderColor}`} key={i} header={header} subHeader={subHeader} icon={icon} direction={direction} padding={padding} isLast={newListCellOptions?.length === i+1} isBottomBordered={isBottomBordered}/>
        }
        return <ListCell textMargin={textMargin} subTextmargin={subTextmargin} border={`0.1px solid ${themes.colors.borderColor}`} key={i} header={header} subHeader={subHeader} icon={icon} link={link} direction={direction} padding={padding} isLast={newListCellOptions?.length === i+1} isBottomBordered={isBottomBordered}/>
      })}
    </div>
  )
  if(header.text) {
    return (
      <Card borderRequired={false}>
        {header.text && <CardTitle pl={'10px'} py='5px' backgroundColor={theme.colors.lightestGrey} mb='0'>{header.text}</CardTitle>}
        {listCard()}
      </Card>)
  }
  return(listCard())
}

ListsWithHeader.defaultProps = {
  listCellOptions: [],
  header: {}
}

export default ListsWithHeader;