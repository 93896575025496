import React, { lazy } from 'react';
import { Redirect, Route, withRouter, useLocation } from 'react-router-dom';
import { AnimatedRoute } from 'react-router-transition';
import { LOGIN } from './public';
import SuspenseLoader from '../components/molecules/SuspenseLoader.js'
import { withLazyLoadingHOC, withErrorBoundaryHOC } from '../utils/hocs';
import postLoginRedirect from '../utils/postLoginRedirect';
import { isMobileWebPwa, queryParams } from '../utils/common';
import { useUserDetails } from '../utils/customHooks';
import MobileWebPwaBanner from '../components/molecules/MobileWebPwaBanner.js';
// Contains list of all details of private routes.
/* Importing required files starts here */
const NewBenefits = lazy(() => import('../pages/NewBenefits'))
const LabTestLandingPage = lazy(() => import('../pages/benefits/health-checkups/LabTestLandingPage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const ArticlesPage = lazy(() => import('../pages/ArticlesPage'));
const ChallengesPage = lazy(() => import('../pages/improve/ChallengesPage'));
const ChallengeViewPage = lazy(() => import('../pages/improve/ChallengeView'));
const ChallengeLeaderBoard = lazy(() => import('../pages/improve/ChallengeLeaderBoard'));
const EventsPage = lazy(() => import('../pages/improve/events/EventsPage')); // only default exports are allowed https://reactjs.org/docs/code-splitting.html#named-exports
const DocumentsPage = lazy(() => import('../pages/phr/DocumentsPage'));
const MedicalHistoryPage = lazy(() => import('../pages/phr/MedicalHistoryPage'));
const Immunizations = lazy(() => import('../pages/phr/ImmunizationsPage'));
const EventDetailsPage = lazy(() => import('../pages/improve/events/EventDetailsPage'));
const Announcementspage = lazy(() => import('../pages/AnnouncementsPage'))
const ProfileEditPage = lazy(() => import('../pages/ProfileEditPage'));
const TeamsScreenPage = lazy(() => import('../pages/improve/teams/TeamsScreen'))
const AmbulanceOnDemand = lazy(() => import(`../pages/benefits/AmbulanceOnDemand`))
const HealthRiskAssessmentPage = lazy(() => import('../pages/HealthRiskAssessmentPage'))
const HealthRiskAssessmentInsightsPage = lazy(() => import('../pages/HealthRiskAssessmentInsightsPage'))
const HealthRiskAssessmentGettingStartedPage = lazy(() => import('../pages/HealthRiskAssessmentGettingStartedPage'))
const HealthRiskAssessmentStepsToImprovePage = lazy(() => import('../pages/HealthRiskAssessmentStepsToImprovePage'))
const WearablesPage = lazy(() => import('../pages/WearablesPage'))
const WearablesCallbackPage = lazy(() => import('../pages/WearablesCallbackPage'))
const WearablesNewPage = lazy(() => import('../pages/WearablesNewPage'))
const WearablesCallbackNewPage = lazy(() => import('../pages/WearablesCallbackNewPage'))
const SettingsPage = lazy(() => import('../pages/SettingsPage'))
const AddNewFamilyMembers = lazy(() => import('../components/organisms/add-family-members/AddNewFamilyMembers.js'));
const PersonalHealthProfile = lazy(() => import('../pages/phr/PersonalHealthProfile'));
const Trends = lazy(() => import('../pages/phr/Trends'));
const FamilyMembers = lazy(() => import('../pages/FamilyMembers'));
const Medications = lazy(() => import('../pages/phr/Medications'));

/* routes exclusively for mobile starts here */
const ImprovePage = lazy(() => import('../pages/improve/ImprovePage'));
const MobileAccountPage = lazy(() => import('../pages/mobile/Account'));
/* routes exclusively for mobile ends here */

const BookingHistoryPage = lazy(() => import('../pages/BookingHistoryPage'))
const ReimbursementPage = lazy(() => import('../pages/ReimbursementPage'))
const AddBankAccountDetails = lazy(() => import('../pages/AddBankAccountDetails.js'))
const VerifyBankAccountDetails = lazy(() => import('../pages/VerifyBankAccountDetails.js'))
const WalletPage = lazy(() => import('../pages/WalletPage'))
const ViewReimbursement = lazy(() => import('../components/organisms/reimbursements/ViewReimbursement.js'))
const Consultation24By7Call = lazy(() => import('../components/organisms/consultation-24-by-7/Consultation24by7'));
const FamilyDoctorChatPage = lazy(() => import('../pages/FamilyDoctorChatPage'));
const AppointmentDetailsPageWrapper = lazy(() => import('../pages/AppointmentDetailsPageWrapper'));
const HealthCheckAppointmentDetailsPage = lazy(() => import('../pages/HealthCheckApptDetails'));
const PharmacyOrderDetails = lazy(() => import('../pages/PharmacyOrderDetails'))
const MedicalReports = lazy(() => import('../pages/phr/MedicalReports'))
const DietPlans = lazy(() => import('../pages/DietPlans'))
const FitnessPlans = lazy(() => import('../pages/FitnessPlans'))
const ConsultationsHistory = lazy(() => import('../pages/phr/ConsultationHistory'))
const Invoices = lazy(() => import('../pages/Invoices'))
const HelpAndSupport = lazy(() => import('../pages/HelpAndSupport'))
const NotificationsPage = lazy(() => import('../pages/NotificationsPage'));
const ValuePlusPage = lazy(() => import('../pages/ValuePlusPage'));
const StressQuestionaire = lazy(() => import('../pages/StressQuestionaire'));
const CovidCheckups = lazy(() => import('../pages/CovidCheckups'))
const EwapConsultationPage = lazy(() => import('../components/organisms/ewap/MentalWellness'))
const EwapAppointmentDetailsPage = lazy(() => import('../pages/EwapAppointmentDetailsPage'));
const HealthCoachConfirmationPage = lazy(() => import('../pages/HealthCoachConfirmationPage'));
const HealthCoachPaymentPage = lazy(() => import('../pages/HealthCoachPaymentPage'));
const BenefitsPage = lazy(() => import('../pages/BenefitsPage'));
const BenefitPlansPage = lazy(() => import('../pages/BenefitPlansPage'));
const GymBookingDetailsPage = lazy(() => import('../pages/GymBookingDetailsPage'))
const RewardsPage = lazy(() => import('../pages/RewardsPage'))
const ValuePlusSavingsPage = lazy(() => import('../pages/ValuePlusSavingsPage'))
const PharmacyPage = lazy(() => import('../pages/benefits/PharmacyPage'))
const GymsPage = lazy(() => import('../pages/benefits/GymsPage'))
const GymsHomePage = lazy(() => import('../pages/benefits/GymsHomePage'))
const FormPage = lazy(() => import('../pages/FormPage'))
const ReferralPage = lazy(() => import('../pages/referral/ReferralPage'))
const GroupHealthInsurancePage = lazy(() => import('../pages/GroupHealthInsurancePage'))
const SpecialistConsultation = lazy(() => import('../components/organisms/specialist-consultation/SpecialistConsultation'))
const PharmacyLandingPage = lazy(() => import('../pages/benefits/PharmacyLandingPage.js'))
const CashlessInclinicConsultation = lazy(() => import('../components/organisms/cashless-inclinic/CashlessInclinicConsultation'))
const HealthPage = lazy(() => import('../pages/HealthPage'))
const PrescriptionUpload = lazy(() => import('../components/organisms/prescription-upload/PrescriptionUpload'));
const ConsentISOS = lazy(() => import ('../components/organisms/onboarding-feature/ConsentIsos'));
const FeedbackScreen = lazy(() => import ('../pages/Feedback.js'));
const HealthCoachProgram = lazy(() => import('../components/molecules/HealthCoachPrograms.js'))
const HealthCoachViewArticle = lazy(() => import('../components/molecules/HealthCoachViewArticle.js'))
const CategoryWisePackages = lazy(() => import('../components/organisms/health-checkups/non-sponsored/CategoryWisePackagesCard'))
const LabtestSearchPackages = lazy(()=> import('../components/organisms/modals/SearchPackages'))
const LabTestBooking= lazy(()=>import('../pages/benefits/health-checkups/LabTestBooking'));
const CashlessBenefitsPage = lazy(() => import('../pages/benefits/CashlessBenefitsPage'))
/* Importing required files ends here */

/* user entered path starts here */
export const HOME = '/';
export const ONGOING_ACTIVITY_CARD = '/dashboard/ongoing-activities/:card_type/:id(\\d+)'
export const ONGOING_ACTIVITY_CARD_CONSULTATION = '/dashboard/ongoing-activities/:card_type/:id(\\d+)/consultation'
export const DASHBOARD = '/dashboard';
export const MARKETING_TILES = '/dashboard/:id(\\d+)';
export const PROFILE = '/profile';
export const WALLET = '/wallet'
export const NOTIFICATIONS = '/notifications';
export const ARTICLES = '/articles';
export const IMPROVE_EVENTS = '/improve/events';
export const HEALTH_RISK_ASSESSMENT = '/health-risk-assessment';
export const HEALTH_RISK_ASSESSMENT_INSIGHTS = `${HEALTH_RISK_ASSESSMENT}/insights`;
export const HEALTH_RISK_ASSESSMENT_GETTING_STARTED = `${HEALTH_RISK_ASSESSMENT}/getting-started`;
export const HEALTH_RISK_ASSESSMENT_STEPS_TO_IMPROVE = `${HEALTH_RISK_ASSESSMENT}/steps-to-improve`;
export const PERSONAL_HEALTH_PROFILE = '/personal-health-profile';
export const PROFILE_EDIT = '/profile/edit';
export const TRENDS = '/trends/';
export const GYMS_HOME_PAGE = '/benefits/gyms'
export const GYMS = '/benefits/gyms/slots';
export const CUSTOMERS_TRENDS = `/trends/:customerToken`
export const BOOKING_HISTORY = `/booking-history`
export const REIMBURSEMENT_PAGE = `/benefits/reimbursements`
export const ADD_BANK_ACCOUNT_DETAILS = `/account-details`
export const VERIFY_BANK_ACCOUNT_DETAILS = `/account-details/verify`
export const PHARMACY_PRESCRIPTION = '/benefits/pharmacy'
export const ANNUAL_HEALTH_CHECKUPS = '/new-benefits/:type';
export const ANNUAL_HEALTH_CHECKUPS_CUSTOMER_ID = '/new-benefits/ahc/:id'
export const ANNUAL_HEALTH_CHECKUPS_CART = '/new-benefits/ahc/cart'
export const ANNUAL_HEALTH_CHECKUPS_ADDRESS = '/new-benefits/ahc/address'
export const NEW_ANNUAL_HEALTH_CHECKUPS = '/new-benefits/ahc';
export const NEW_VISION_CHECKUPS = '/new-benefits/vision';
export const NEW_FERTILITY_TREATMENT = '/new-benefits/fertility_treatment'
export const NEW_DENTAL_CHECKUPS = '/new-benefits/dental';
export const NEW_COVID_CHECKUPS = '/new-benefits/covid_vaccination';
export const NEW_FLU_CHECKUPS = '/new-benefits/flu_vaccination';
export const OLD_ANNUAL_HEALTH_CHECKUPS = '/annual-health-checkups';
export const OLD_VISION_CHECKS_FLOW = '/benefits/vision-checks';
export const OLD_DENTAL_CHECKS_FLOW = '/benefits/dental-checks';
export const OLD_COVID_FLOW = '/benefits/covid_vaccination';
export const OLD_FLU_VACCINATION_FLOW = '/benefits/flu_vaccination';
export const OLD_VACCINATION_FLOW = '/benefits/vaccination';
export const SPECIALIST_CONSULTATION = '/benefits/spc'
export const AMBULANCE_ON_DEMAND = '/benefits/ambulance';
export const INCLINIC = '/benefits/inclinic';
export const CASHLESS_INCLINIC = '/benefits/cashless/inclinic'
export const NORMAL_HEALTH_CHECKUPS = `/normal-health-checkups`;
export const LAB_TESTS = `/lab-tests`;
export const LAB_TESTS_BOOKING = `/lab-tests/booking`;
export const LAB_TESTS_SEARCH = `/lab-tests/search`;
export const NHC_DEEPLINK = `/benefits/nhc`
export const IMPROVE_CHALLENGES = '/improve/challenges';
export const DENTAL_CHECKUPS = '/dental-checkups';
export const DENTAL_CHECKUPS_FAMILY = '/dental-checkups/family';
export const VISION_CHECKUPS = '/vision-checkups';
export const VISION_CHECKUPS_FAMILY = '/vision-checkups/family';
export const DOCUMENTS = '/documents';
export const DOCUMENT_VIEW = '/documents/:document_id(\\d+)';

export const MEDICAL_HISTORY = '/medical-history'
export const VACCINATIONS = '/benefits/vaccinations';
export const VACCINATIONS_FAMILY = '/benefits/vaccinations/family';
export const IMPROVE_PAGE = '/improve'; 
export const MOBILE_ACCOUNT = '/mobile/account';
export const IMPROVE_CHALLENGE_VIEW = '/improve/challenges/:id(\\d+)/info';
export const IMPROVE_CUSTOMER_CHALLENGE_VIEW = '/improve/challenges/:customer_challenge_id(\\d+)';
export const IMPROVE_CHALLENGE_LEADER_BOARD = '/improve/challenges/leaderboard';
export const INDIVIDUAL_LEADER_BOARD = '/leaderboard';
export const TEAM_LEADER_BOARD = '/teams/leaderboard';
export const IMPROVE_CHALLENGE_TEAMS = '/improve/challenges/leaderboard/:teams';
export const IMPROVE_CHALLENGE_LEADER_BOARD_CREATE = '/improve/challenges/leaderboard/teams/create';
export const IMPROVE_CHALLENGE_LEADER_BOARD_TEAM_DETAILS = '/improve/challenges/leaderboard/teams/:team_id(\\d+)/details';
export const IMPROVE_CHALLENGE_INVITATIONS = '/improve/challenges/:customer_challenge_id(\\d+)/invitations';
export const IMPROVE_CHALLENGE_INVITATION = '/improve/challenges/:customer_challenge_id(\\d+)/invitations/:invitation_id(\\d+)';
export const IMPROVE_CHALLENGE_TEAM = '/improve/challenges/teams';
export const IMPROVE_TEAMS = '/teams';
export const IMPROVE_TEAMS_DETAILS = '/teams/:team_id(\\d+)';
export const IMPROVE_MY_TEAMS = '/myteam';
export const PRESCRIPTION_UPLOAD = '/prescription-upload'
export const NORMAL_HEALTH_CATEGORY= `/lab-tests/categories`;
export const ORDER_MEDICINES = '/order-medicines';
export const WEARABLES = '/wearables';
export const WEARABLES_NEW = '/wearables-new'
export const ADD_WEARABLE = '/wearables/new';
export const SETTINGS = '/settings';
export const WEARABLES_CALLBACK = '/wearables/callback';
export const WEARABLES_CALLBACK_NEW = '/wearables/callback-new';
export const IMMUNIZATIONS = '/immunizations';
export const EVENTS = '/events'
export const EVENT_DETAILS = `/events/:eventId`
export const ADD_FAMILY_MEMBERS = `/add-family-members`;
export const ADD_NEW_FAMILY_MEMBERS = `/add-new-family-members`;
export const FAMILY_MEMBERS = '/family-members';
export const MEDICATIONS = '/medications';
export const MEDICATIONS_VIEW = '/medications/:prescription_id(\\d+)';
export const ANNOUNCEMENTS = '/announcements'
export const ARTICLE_DETAILS = '/articles/:id(\\d+)';
export const FAMILY_DOCTOR_GETTING_STARTED = '/family-doctor/getting-started'
export const CALL_CONSULTATION_24BY7 = '/benefits/consultation24x7/call'; //'/family-doctor/consultation';
export const CONSULTATION_24X7 = '/benefits/consultation24x7';
export const OLD_CONSULTATION_24X7 = '/family-doctor/chat';
export const OLD_CONSULTATION_24X7_2 = '/consultations';
export const APPOINTMENT_DETAILS = '/appointments/:id(\\d+)';
export const HEALTH_CHECK_APPOINTMENT_DETAILS = '/health-checks/:id(\\d+)';
export const GYM_APPOINTMENT_DETAILS = '/gyms/:id(\\d+)';
export const HEALTH_CHECKS = '/health-checks';
export const APPOINTMENTS = '/appointments'
export const HEALTH_PAGE = '/health' 
/* user entered path ends here */

export const PHARMACY_ORDER_DETAILS = '/order-details'
export const PHARMACY_TRACK = '/order-track'
export const PHARMACY_TRACKING = '/pharmacy-tracking'
export const MEDICAL_REPORTS = '/medical-reports'
export const CONSULTATIONS_HISTORY = '/consultations-history'
export const CONSULTATIONS_HISTORY_DETAILS = '/consultations-history/:consultation_id(\\d+)'

export const DIET_PLANS = '/diet-plans'
export const FITNESS_PLANS = '/fitness-plans'
export const INVOICES = '/invoices'
export const HELPSUPPORT = '/support'
export const VIEW_INVOICE = '/invoice/:invoice_id(\\d+)'


export const STRESS_QUESTIONAIRE = '/stress-questionnaire'
export const COVID_CHECKUPS = '/covid-19'

export const EMPLOYEE_WELLBEING = '/employee-wellbeing'
export const EWAP_CONSULTATION_PAGE = '/ewap-consultation';
export const EWAP_APPOINTMENTS = '/ewap-appointments'
export const EWAP_APPOINTMENT_DETAILS = '/ewap-appointments/:id(\\d+)';
export const HEALTH_COACH_CONFIRMATION = '/health-coach/:id(\\d+)';
export const HEALTH_COACH_PAYMENT = '/health-coach/payment';
export const BENEFITS = '/benefits';
export const BENEFIT_PLANS = '/benefit-plans';
export const HEALTH_CHECK_BENEFITS = '/benefits/health_checks';
export const CONSULTATION_BENEFITS = '/benefits/consultations';
export const WALLET_SPONSORED_BENEFITS = '/benefits/wallet_sponsored_benefits';
export const SPONSORED_BENEFITS = '/benefits/sponsored_benefits';
export const MEDICAL_REPORTS_DETAILS = '/medical-reports/:assessment_id(\\d+)'
export const RESCHEDULE_APPOINTMENT = '/appointments/:appointment_id(\\d+)/reschedule'
export const REWARDS = '/rewards'
export const VALUE_PLUS_SAVINGS_PAGE = '/value_plus_savings'
export const REWARDS_DEEP_LINK = '/rewards/:type'
export const MY_DOCUMENTS = '/my-documents'
export const PHARMACY = '/benefits/pharmacy/product'
export const PHARMACY_CART = '/benefits/pharmacy/cart'
export const PHARMACY_CATEGORY = '/benefits/:benefit_type/:product_category/:category_id(\\d+)'
export const PHARMACY_WITH_PRESCRIPTION = '/benefits/:benefit_type/:prescription_slug'
export const BENEFITS_VACCINATIONS = '/benefits/vaccination'
export const HISTORY = '/history'
export const MENTAL_WELLNESS = `/benefits/ewap`;
export const CONSENT_ISOS = '/consentisos'
export const FEEDBACK_SCREEN = '/feedback'
export const HEALTH_COACH_PROGRAM = '/health-coach/:enrollment_id(\\d+)/timeline'
export const HEALTH_COACH_ARTICLE = '/health-coach-article'

export const FORM = '/form'

//smaller routes for SMSs
export const DASHBOARD_SMS = '/d';
export const MEDICAL_REPORTS_DETAILS_SMS = '/m/:assessment_id(\\d+)'
export const RESCHEDULE_APPOINTMENT_SMS = '/a/:appointment_id(\\d+)/reschedule'
export const CONSULTATIONS_HISTORY_DETAILS_SMS = '/c/:consultation_id(\\d+)'
export const MEDICATIONS_VIEW_SMS = '/e/:prescription_id(\\d+)';
export const IMPROVE_CHALLENGES_SMS = '/h';
export const IMPROVE_CHALLENGE_VIEW_SMS = '/h/:id(\\d+)/info';
export const EVENTS_SMS = '/v'
export const EVENT_DETAILS_SMS = `/v/:eventId`
export const ANNUAL_HEALTH_CHECKUPS_SMS = '/ahc';
export const VIEW_INVOICE_SMS = '/in/:invoice_id(\\d+)'
export const IMPROVE_CHALLENGE_INVITATIONS_SMS = '/h/:customer_challenge_id(\\d+)/in';
export const IMPROVE_CHALLENGE_INVITATION_SMS = '/h/:customer_challenge_id(\\d+)/in/:invitation_id(\\d+)';
export const DOCUMENT_SMS = '/o/:document_id(\\d+)';
export const FEEDBACK_RATING = '/feedback/:type/:id(\\d+)/:rating(\\d+)';
export const VIEW_REIMBURSEMENT = `/benefits/reimbursements/:id(\\d+)`;
// referral api's

export const HEALTHBUDDY = '/healthbuddy'

// group health insurance
export const HEALTH_INSURANCE = '/group_health_insurance'

// value plus routes
export const VALUE_PLUS = '/value_plus'

//cashless benefits
export const CASHLESS_BENEFITS_PAGE = '/cashless-benefits-page'

export const PRIVATE_ROUTES = [
  { path: CONSENT_ISOS, component: withLazyLoadingHOC(ConsentISOS) },
  { path: FEEDBACK_SCREEN , component: withLazyLoadingHOC(FeedbackScreen)},
  { path: HEALTH_COACH_PROGRAM , component: withLazyLoadingHOC(HealthCoachProgram)},
  { path: HEALTH_COACH_ARTICLE , component: withLazyLoadingHOC(HealthCoachViewArticle)},
  { path: HOME, component: withLazyLoadingHOC(DashboardPage) },
  { path: ONGOING_ACTIVITY_CARD, component: withLazyLoadingHOC(DashboardPage) },
  { path: ONGOING_ACTIVITY_CARD_CONSULTATION, component: withLazyLoadingHOC(DashboardPage) },
  { path: FEEDBACK_RATING, component: withLazyLoadingHOC(DashboardPage) },
  { path: PROFILE, component: withLazyLoadingHOC(DashboardPage) },
  { path: DASHBOARD, component: withLazyLoadingHOC(DashboardPage) },
  { path: MARKETING_TILES, component: withLazyLoadingHOC(DashboardPage) },
  { path: DASHBOARD_SMS, component: withLazyLoadingHOC(DashboardPage) },
  { path: ARTICLES, component: withLazyLoadingHOC(ArticlesPage) },
  { path: ARTICLE_DETAILS, component: withLazyLoadingHOC(ArticlesPage) },
  { path: EVENTS, component: withLazyLoadingHOC(EventsPage), exact: true },
  { path: EVENTS_SMS, component: withLazyLoadingHOC(EventsPage), exact: true },
  { path: EVENT_DETAILS, component: withLazyLoadingHOC(EventDetailsPage) },
  { path: EVENT_DETAILS_SMS, component: withLazyLoadingHOC(EventDetailsPage) },
  { path: PHARMACY_PRESCRIPTION, component: withLazyLoadingHOC(PharmacyLandingPage), exact: true },
  { path: ANNUAL_HEALTH_CHECKUPS, component: withLazyLoadingHOC(NewBenefits) },
  { path: ANNUAL_HEALTH_CHECKUPS_CUSTOMER_ID, component: withLazyLoadingHOC(NewBenefits) },
  { path: ANNUAL_HEALTH_CHECKUPS_CART, component: withLazyLoadingHOC(NewBenefits) },
  { path: ANNUAL_HEALTH_CHECKUPS_ADDRESS, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_ANNUAL_HEALTH_CHECKUPS, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_VISION_CHECKS_FLOW, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_DENTAL_CHECKS_FLOW, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_COVID_FLOW, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_VACCINATION_FLOW, component: withLazyLoadingHOC(NewBenefits) },
  { path: OLD_FLU_VACCINATION_FLOW, component: withLazyLoadingHOC(NewBenefits) },
  { path: MENTAL_WELLNESS, component: withLazyLoadingHOC(EwapConsultationPage) },
  { path: SPECIALIST_CONSULTATION, component: withLazyLoadingHOC(SpecialistConsultation), exact: true },
  { path: INCLINIC, component: withLazyLoadingHOC(SpecialistConsultation), exact: true },
  { path: CASHLESS_INCLINIC, component: withLazyLoadingHOC(CashlessInclinicConsultation), exact: true },
  { path: NORMAL_HEALTH_CHECKUPS, component: withLazyLoadingHOC(LabTestLandingPage) },
  { path: NHC_DEEPLINK, component: withLazyLoadingHOC(LabTestLandingPage) },
  { path: LAB_TESTS,component: withLazyLoadingHOC(LabTestLandingPage)},
  { path: LAB_TESTS_BOOKING,component:withLazyLoadingHOC(LabTestBooking)},
  { path: LAB_TESTS_SEARCH,component:withLazyLoadingHOC(LabtestSearchPackages)},
  { path: NORMAL_HEALTH_CATEGORY, component: withLazyLoadingHOC(CategoryWisePackages) },
  { path: IMPROVE_CHALLENGES, component: withLazyLoadingHOC(ChallengesPage), exact: true },
  { path: IMPROVE_CHALLENGES_SMS, component: withLazyLoadingHOC(ChallengesPage), exact: true },
  { path: DOCUMENTS, component: withLazyLoadingHOC(DocumentsPage) },
  { path: DOCUMENT_VIEW, component: withLazyLoadingHOC(DocumentsPage) },
  { path: DOCUMENT_SMS, component: withLazyLoadingHOC(DocumentsPage) },
  { path: MEDICAL_HISTORY, component: withLazyLoadingHOC(MedicalHistoryPage) },
  { path: HEALTH_RISK_ASSESSMENT_INSIGHTS, component: withLazyLoadingHOC(HealthRiskAssessmentInsightsPage), exact: true },
  { path: HEALTH_RISK_ASSESSMENT_GETTING_STARTED, component: withLazyLoadingHOC(HealthRiskAssessmentGettingStartedPage), exact: true },
  { path: HEALTH_RISK_ASSESSMENT, component: withLazyLoadingHOC(HealthRiskAssessmentPage), exact: true },
  { path: HEALTH_RISK_ASSESSMENT_STEPS_TO_IMPROVE, component: withLazyLoadingHOC(HealthRiskAssessmentStepsToImprovePage) },
  { path: IMPROVE_EVENTS, component: withLazyLoadingHOC(EventsPage), exact: true, animated: true },
  { path: IMPROVE_PAGE, component: withLazyLoadingHOC(ImprovePage), exact: true }, 
  { path: MOBILE_ACCOUNT, component: withLazyLoadingHOC(MobileAccountPage), exact: true, animated: true },
  { path: IMPROVE_CHALLENGE_LEADER_BOARD, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: INDIVIDUAL_LEADER_BOARD, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: TEAM_LEADER_BOARD, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: IMPROVE_CHALLENGE_LEADER_BOARD_CREATE, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: IMPROVE_CHALLENGE_LEADER_BOARD_TEAM_DETAILS, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: IMPROVE_CHALLENGE_TEAMS, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: IMPROVE_CHALLENGE_TEAMS, component: withLazyLoadingHOC(ChallengeLeaderBoard), exact: true },
  { path: IMPROVE_CUSTOMER_CHALLENGE_VIEW, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_VIEW, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_INVITATIONS, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_INVITATIONS_SMS, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_INVITATION_SMS, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_INVITATION, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: IMPROVE_CHALLENGE_VIEW_SMS, component: withLazyLoadingHOC(ChallengeViewPage), exact: true },
  { path: NOTIFICATIONS, component: withLazyLoadingHOC(NotificationsPage), exact: true },
  { path: VALUE_PLUS, component: withLazyLoadingHOC(ValuePlusPage), exact: true },
  { path: WEARABLES, component: withLazyLoadingHOC(WearablesPage), exact: true },
  { path: WEARABLES_NEW, component: withLazyLoadingHOC(WearablesNewPage), exact: true },
  { path: ADD_WEARABLE, component: withLazyLoadingHOC(WearablesPage), exact: true },
  { path: SETTINGS, component: withLazyLoadingHOC(SettingsPage), exact: true },
  { path: WEARABLES_CALLBACK, component: withLazyLoadingHOC(WearablesCallbackPage), exact: true },
  { path: WEARABLES_CALLBACK_NEW, component: withLazyLoadingHOC(WearablesCallbackNewPage), exact: true },
  { path: IMMUNIZATIONS, component: withLazyLoadingHOC(Immunizations), exact: true },
  { path: ADD_FAMILY_MEMBERS, component: withLazyLoadingHOC(AddNewFamilyMembers), exact: true },
  { path: ADD_NEW_FAMILY_MEMBERS, component: withLazyLoadingHOC(AddNewFamilyMembers), exact: true },
  { path: PERSONAL_HEALTH_PROFILE, component: withLazyLoadingHOC(PersonalHealthProfile), exact: true },
  { path: CUSTOMERS_TRENDS, component: withLazyLoadingHOC(Trends), exact: true },
  { path: BOOKING_HISTORY, component: withLazyLoadingHOC(BookingHistoryPage), exact: true },
  { path: CASHLESS_BENEFITS_PAGE, component: withLazyLoadingHOC(CashlessBenefitsPage), exact: true },
  { path: REIMBURSEMENT_PAGE, component: withLazyLoadingHOC(ReimbursementPage), exact: true },
  { path: VIEW_REIMBURSEMENT, component: withLazyLoadingHOC(ViewReimbursement), exact: true },
  { path: ADD_BANK_ACCOUNT_DETAILS, component: withLazyLoadingHOC(AddBankAccountDetails), exact: true },
  { path: VERIFY_BANK_ACCOUNT_DETAILS, component: withLazyLoadingHOC(VerifyBankAccountDetails), exact: true },
  { path: FAMILY_MEMBERS, component: withLazyLoadingHOC(FamilyMembers), exact: true },
  { path: MEDICATIONS, component: withLazyLoadingHOC(Medications), exact: true },
  { path: MEDICATIONS_VIEW, component: withLazyLoadingHOC(Medications), exact: true },
  { path: MEDICATIONS_VIEW_SMS, component: withLazyLoadingHOC(Medications), exact: true },
  { path: ANNOUNCEMENTS, component: withLazyLoadingHOC(Announcementspage), exact: true },
  { path: PROFILE_EDIT, component: withLazyLoadingHOC(ProfileEditPage), exact: true },
  { path: FAMILY_DOCTOR_GETTING_STARTED, component: withLazyLoadingHOC(FamilyDoctorChatPage), exact: true },
  { path: CALL_CONSULTATION_24BY7, component: withLazyLoadingHOC(Consultation24By7Call), exact: true },
  { path: CONSULTATION_24X7, component: withLazyLoadingHOC(FamilyDoctorChatPage), exact: true },
  { path: OLD_CONSULTATION_24X7, component: withLazyLoadingHOC(FamilyDoctorChatPage), exact: true },
  { path: OLD_CONSULTATION_24X7_2, component: withLazyLoadingHOC(FamilyDoctorChatPage), exact: true },
  { path: APPOINTMENT_DETAILS, component: withLazyLoadingHOC(AppointmentDetailsPageWrapper), exact: true },
  { path: HEALTH_CHECK_APPOINTMENT_DETAILS, component: withLazyLoadingHOC(HealthCheckAppointmentDetailsPage), exact: true },
  { path: GYM_APPOINTMENT_DETAILS, component: withLazyLoadingHOC(GymBookingDetailsPage), exact: true },
  { path: PHARMACY_ORDER_DETAILS, component: withLazyLoadingHOC(PharmacyOrderDetails), exact: true },
  { path: PHARMACY_TRACK, component: withLazyLoadingHOC(PharmacyOrderDetails), exact: true },
  { path: MEDICAL_REPORTS, component: withLazyLoadingHOC(MedicalReports), exact: true },
  { path: MEDICAL_REPORTS_DETAILS, component: withLazyLoadingHOC(MedicalReports), exact: true },
  { path: MEDICAL_REPORTS_DETAILS_SMS, component: withLazyLoadingHOC(MedicalReports), exact: true },
  { path: DIET_PLANS, component: withLazyLoadingHOC(DietPlans), exact: true },
  { path: FITNESS_PLANS, component: withLazyLoadingHOC(FitnessPlans), exact: true },
  { path: CONSULTATIONS_HISTORY, component: withLazyLoadingHOC(ConsultationsHistory), exact: true },
  { path: CONSULTATIONS_HISTORY_DETAILS, component: withLazyLoadingHOC(ConsultationsHistory), exact: true },
  { path: CONSULTATIONS_HISTORY_DETAILS_SMS, component: withLazyLoadingHOC(ConsultationsHistory), exact: true },
  {path: HELPSUPPORT, component: withLazyLoadingHOC(HelpAndSupport), exact: true},
  { path: INVOICES, component: withLazyLoadingHOC(Invoices), exact: true },
  { path: VIEW_INVOICE, component: withLazyLoadingHOC(Invoices), exact: true },
  { path: VIEW_INVOICE_SMS, component: withLazyLoadingHOC(Invoices), exact: true },
  { path: STRESS_QUESTIONAIRE, component: withLazyLoadingHOC(StressQuestionaire), exact: true },
  { path: GYMS, component: withLazyLoadingHOC(GymsPage), exact: true },
  { path: GYMS_HOME_PAGE, component: withLazyLoadingHOC(GymsHomePage), exact: true },
  { path: COVID_CHECKUPS, component: withLazyLoadingHOC(CovidCheckups), exact: true },
  { path: COVID_CHECKUPS, component: withLazyLoadingHOC(CovidCheckups), exact: true },
  { path: EMPLOYEE_WELLBEING, component: withLazyLoadingHOC(EwapConsultationPage), exact: true },
  { path: EWAP_CONSULTATION_PAGE, component: withLazyLoadingHOC(EwapConsultationPage), exact: true },
  { path: EWAP_APPOINTMENT_DETAILS, component: withLazyLoadingHOC(EwapAppointmentDetailsPage), exact: true },
  { path: HEALTH_COACH_CONFIRMATION, component: withLazyLoadingHOC(HealthCoachConfirmationPage), exact: true },
  { path: HEALTH_COACH_PAYMENT, component: withLazyLoadingHOC(HealthCoachPaymentPage), exact: true },
  { path: BENEFITS, component: withLazyLoadingHOC(BenefitsPage), exact: true },
  { path: HEALTH_CHECK_BENEFITS, component: withLazyLoadingHOC(BenefitsPage), exact: true },
  { path: CONSULTATION_BENEFITS, component: withLazyLoadingHOC(BenefitsPage), exact: true },
  { path: WALLET_SPONSORED_BENEFITS, component: withLazyLoadingHOC(BenefitsPage), exact: true },
  { path: SPONSORED_BENEFITS, component: withLazyLoadingHOC(BenefitsPage), exact: true },
  { path: BENEFIT_PLANS, component: withLazyLoadingHOC(BenefitPlansPage), exact: true},
  { path: AMBULANCE_ON_DEMAND, component: withLazyLoadingHOC(AmbulanceOnDemand), exact: true },
  { path: RESCHEDULE_APPOINTMENT, component: withLazyLoadingHOC(BookingHistoryPage), exact: true },
  { path: RESCHEDULE_APPOINTMENT_SMS, component: withLazyLoadingHOC(BookingHistoryPage), exact: true },
  { path: REWARDS, component: withLazyLoadingHOC(RewardsPage), exact: true },
  { path: VALUE_PLUS_SAVINGS_PAGE, component: withLazyLoadingHOC(ValuePlusSavingsPage), exact: true },
  { path: REWARDS_DEEP_LINK, component: withLazyLoadingHOC(RewardsPage), exact: true },
  { path: PHARMACY, component: withLazyLoadingHOC(PharmacyPage), exact: true },
  { path: PHARMACY_CART, component: withLazyLoadingHOC(PharmacyPage), exact: true },
  { path: PHARMACY_CATEGORY, component: withLazyLoadingHOC(PharmacyLandingPage), exact: true },
  { path: PHARMACY_WITH_PRESCRIPTION, component: withLazyLoadingHOC(PharmacyLandingPage), exact: true },
  { path: WALLET, component: withLazyLoadingHOC(WalletPage), exact: true },
  { path: FORM, component: withLazyLoadingHOC(FormPage), exact: true },
  { path: HEALTHBUDDY, component: withLazyLoadingHOC(ReferralPage), exact: true },
  { path: HEALTH_INSURANCE, component: withLazyLoadingHOC(GroupHealthInsurancePage), exact: true },
  { path: IMPROVE_CHALLENGE_TEAM, component: withLazyLoadingHOC(TeamsScreenPage), exact: true },
  { path: IMPROVE_TEAMS, component: withLazyLoadingHOC(TeamsScreenPage), exact: true },
  { path: IMPROVE_MY_TEAMS, component: withLazyLoadingHOC(TeamsScreenPage), exact: true },
  { path: IMPROVE_TEAMS_DETAILS, component: withLazyLoadingHOC(TeamsScreenPage), exact: true },
  { path: HEALTH_PAGE, component: withLazyLoadingHOC(HealthPage), exact: true },
  { path: PRESCRIPTION_UPLOAD, component: withLazyLoadingHOC(PrescriptionUpload), exact: true }

].map((route, index) => ({ ...route, key: index, component: withRouter(withErrorBoundaryHOC(route.component)) })) // withRouter helps us in accessing history.

export const usePrivateRoutes = (showMobileWebBanner) => {
  const { isAuthenticated, isLoading } = useUserDetails(true)
  const hasPrescriptionPending = window.localStorage.getItem('HAS_PRESCRIPTION_PENDING') === "TRUE";
  const location = useLocation();
  if (isLoading) {
    return (<SuspenseLoader />)
  } else if (!isAuthenticated) {
    postLoginRedirect.push(location.pathname + (location.search ? location.search : ''))
    return <Redirect to={LOGIN} />
  }
 
  if (hasPrescriptionPending) {
    return (
      <>
        <Route path={PRESCRIPTION_UPLOAD} render={withLazyLoadingHOC(PrescriptionUpload)} exact={true} />
        <Redirect to={PRESCRIPTION_UPLOAD} />
      </>
    )
  }
  return PRIVATE_ROUTES.map((route, index) => {
    if (route.animated) {
      return (
        <AnimatedRoute {...route}
          atEnter={{ offset: -100 }}
          atLeave={{ offset: -100 }}
          atActive={{ offset: 0 }}
          mapStyles={(styles) => ({
            transform: `translateX(${styles.offset}%)`,
          })} />)
    } else {
      const { component: Component, ...rest } = route;
      return (<Route {...rest} render={(props) => {
        const { pageRefresh, uniqueId, identification_token } = queryParams(window.location.search)
        if (pageRefresh === 'true') {
          return <>
            {(isMobileWebPwa() && showMobileWebBanner) && <MobileWebPwaBanner />}
            <Component {...props} key={identification_token || uniqueId} {...rest} />
          </>
        } else {
          return <>
            {(isMobileWebPwa() && showMobileWebBanner) && <MobileWebPwaBanner />}
            <Component {...props} {...rest} />
          </>
        }
      }} key={index} exact />)
    }
  })
}

export default usePrivateRoutes;