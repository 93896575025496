import React from 'react'
import { Icon, ToggleVisibility } from '../../atoms';
// import Text from '../../atoms/typography/Text';
import notificationNew from '../../../assets/icons/notificationNew.svg';
import notificationAlertNew from '../../../assets/icons/notificationAlertNew.svg';
import { useHistory } from 'react-router-dom';
import { MOBILE_ACCOUNT, NOTIFICATIONS } from '../../../routes/private';
import useFetchNotifications from '../../../utils/customHooks/useFetchNotifications';
import useIsMobileView from '../../../utils/customHooks/useIsMobileView';
import useUserDetails from '../../../utils/customHooks/useUserDetails';
import { getNameInitialization } from '../../../utils/common';
import tracker from '../../../utils/tracker';
import { ekincareLogo } from '../../../utils/constants';
import HPoints from '../../molecules/HPoints';

const NameBanner = () => {
  const { push } = useHistory();
  const { partner_logo, company_logo, user } = useUserDetails();
  const logo_src = partner_logo || company_logo || ekincareLogo;
  const fullName = user?.name || user?.first_name;
  const { checkUnreadNotifications } = useFetchNotifications();
  const isMobileView = useIsMobileView();
  const notification_scr = checkUnreadNotifications() ? notificationAlertNew : notificationNew;

  const notificationClickHandler = () => (
    push(NOTIFICATIONS),
    tracker.track('hs_notifications')
  )

  const profileClickHandler = () => (
    push(MOBILE_ACCOUNT),
    tracker.track('hs_user_profile')
  )

  return (
    <div className='flex justify-between items-center'>
      <ToggleVisibility visibleOn={{mobile: true, desktop: false, tablet: false}}>
        {partner_logo !== '' && <Icon height='auto' width='auto' maxHeight={'45px'} maxWidth={'107px'} src={logo_src}/>}
      </ToggleVisibility>
      <div className='flex items-center'>
        <HPoints />
        <img className={`cursor-pointer h-10 w-10 rounded-lg p-2 hover:bg-blue-100 ${isMobileView ? '' : 'mr-4'}`} onClick={notificationClickHandler} src={notification_scr}/>
      </div>
      <ToggleVisibility visibleOn={{mobile: false, desktop: true, tablet: true}}>
        <p className='cursor-pointer flex justify-center items-center w-10 h-10 rounded-full tracking-wider font-fig-semibold text-base bg-green-200 text-gray-800' onClick={profileClickHandler}>{getNameInitialization(fullName)}</p>
      </ToggleVisibility>
    </div>
  )
}
export default NameBanner;