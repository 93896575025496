import request from "../utils/request";
import {
  API_CREATE_VALUE_PLUS_SUBSCRIPTIONS,
  API_EXTEND_VALUE_PLUS_SUBSCRIPTION,
  API_GET_AVAILABLE_VALUE_PLUS_SUBSCRIPTIONS,
  API_GET_PAYMENT_DETAILS_VALUE_PLUS_SUBSCRIPTIONS,
  API_GET_VALUE_PLUS_SUBSCRIPTION_STATUS,
  API_PATCH_CONFIRM_VALUE_PLUS_SUBSCRIPTION
} from "../routes/api";

export default class ValuePlusAPI {
  static availableSubscriptions = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_GET_AVAILABLE_VALUE_PLUS_SUBSCRIPTIONS,
        method: 'get'
      },
      onSuccess,
      onError
    )
  }

  static paymentDetails = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_GET_PAYMENT_DETAILS_VALUE_PLUS_SUBSCRIPTIONS(options.id),
        method: 'get'
      },
      onSuccess,
      onError
    )
  }

  static createSubscription = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_CREATE_VALUE_PLUS_SUBSCRIPTIONS,
        method: 'post'
      },
      onSuccess,
      onError
    )
  }

  static extendSubscription = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_EXTEND_VALUE_PLUS_SUBSCRIPTION,
        method: 'post'
      },
      onSuccess,
      onError
    )
  }

  static confirmSubscription = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_PATCH_CONFIRM_VALUE_PLUS_SUBSCRIPTION(options.id),
        method: 'patch'
      },
      onSuccess,
      onError
    )
  }

  static subscriptionStatus = (options, onSuccess, onError) => {
    return request(
      {
        ...options,
        url: API_GET_VALUE_PLUS_SUBSCRIPTION_STATUS(options.id),
        method: 'get'
      },
      onSuccess,
      onError
    )
  }
}