import React from 'react';
import styled from 'styled-components';
import barbell from '../../../assets/icons/barbell.svg';
import { Icon } from '../../atoms';
import { Link, withRouter } from 'react-router-dom';

const Container = styled(Link)`
  display: flex;
  background-color: white;
  margin-bottom: 0.5em;
  width: 50%;
  text-decoration: none;
`;

const IconContainer = styled.div`
  height: 100%;
  margin: auto 10px;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 10px;
  max-width: 25rem;
  width: 20rem;
`;

const Header = styled.div`
  font-family: "Figtree-SemiBold";
  font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
  color: ${({ theme }) => theme.colors.darkestGrey};
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  cursor: pointer;
`;

const SubHeader = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
  color: ${({ theme }) => theme.colors.darkerGrey};
  margin: 1px 0;
`;

const MenuItem = ({ history, header, eventName,eventData, subHeader, icon, link, onClick }) => {
  const { src = barbell, size } = icon;
  const { to, external } = link;
  const onLinkClick = (...args) => {
    onClick && onClick(...args)
    if(external) {
      window.open(to, '_blank');
    } else {
      history.push(to)
    }
  }
  return (
    <Container data-event-name={eventName} data-event-data={JSON.stringify(eventData)} onClick={onLinkClick}>
      <IconContainer data-event-name={eventName}>
        {React.isValidElement(icon) ? (
          icon
        ) : (
          <Icon src={src} size={size || 'xxl'} />
        )}
      </IconContainer>
      <TextContainer>
        <Header data-event-name={eventName} data-event-data={JSON.stringify(eventData)}>{header}</Header>
        <SubHeader data-event-name={eventName} data-event-data={JSON.stringify(eventData)}>{subHeader}</SubHeader>
      </TextContainer>
    </Container>
  );
};

MenuItem.defaultProps = {
  link: {},
  icon: {}
};

export default withRouter(MenuItem);
