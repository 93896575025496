import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Button from './Button';
import { media } from '../../utils/mediaBreakpoints';

const InputDecrement = styled(Button)`
  border: ${props => props.borderRequired ? '2px solid lightGrey' : 'none'};
  height: ${props => props.height || '40px'};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 32px;
  line-height: 38px;
  background: ${({theme:{colors}, decrementBgColor}) => colors[decrementBgColor]};
  color: ${({theme:{colors}, decrementOperatorColor}) => colors[decrementOperatorColor]};
  text-align: center;
  font-family: "Figtree-SemiBold";
  border-right: none;
  border-radius: 4px 0 0 4px;
  -webkit-tap-highlight-color: transparent;
  ${media.mobile`
    background: ${({theme:{colors}, decrementBgColor}) => colors[decrementBgColor]};
    border-radius: ${props => props.mobileInputDecrementBorder || '4px 0 0 4px'};
    font-size: ${props => props.mobileFontSize || '14px'}; 
    width: ${props => props.mobileWidth || '24px'};
    height: ${props => props.mobileHeight || '24px'};
  `}
`

const InputIncrement = styled(Button)`
	border: ${props => props.borderRequired ? '2px solid lightGrey' : 'none'};
  height: ${props => props.height || '40px'};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 32px;
  line-height: 38px;
  background: ${({theme:{colors}, incrementBgColor}) => colors[incrementBgColor]};
  color: ${({theme:{colors}, incrementOperatorColor}) => colors[incrementOperatorColor]};
  text-align: center;
  border-left: none;
  border-radius: 0 4px 4px 0;
  -webkit-tap-highlight-color: transparent;
  ${media.mobile`
    border-radius: ${props => props.mobileInputIncrementBorder || '0 4px 4px 0'};
    background: ${({theme:{colors}, incrementBgColor}) => colors[incrementBgColor]};
    font-size: ${props => props.mobileFontSize || '14px'}; 
    width: ${props => props.mobileWidth || '24px'};
    height: ${props => props.mobileHeight || '24px'};
  `}
`

const InputNumber = styled.input.attrs({type: "number"})`
	width: ${props => props.inputWidth || '80px'};
  font-family: Figtree-Bold; 
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
  border: ${props => props.borderRequired ? '2px solid lightGrey' : 'none'};
  background: ${({theme:{colors}, inputNumberBg}) => colors[inputNumberBg]};
  height: ${props => props.height || '40px'};
  user-select: none;
  ::-webkit-inner-spin-button, 
	::-webkit-outer-spin-button { 
			-webkit-appearance: none;  
  }
  -moz-appearance: textfield;
  -webkit-tap-highlight-color: transparent;
  ${media.mobile`
    font-size: ${props => props.mobileInputSize || '14px'}; 
    width: ${props => props.mobileWidth || '28px'};
    height: ${props => props.mobileHeight || '24px'};
    border-left: ${props => props.hideLRInputBorder && 'none'};
    border-right: ${props => props.hideLRInputBorder && 'none'};
    padding: 0;
  `}
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    border: ${props => props.borderRequired ? '2px solid lightGrey' : 'none'};
    background: ${({theme:{colors}, inputNumberBg}) => colors[inputNumberBg]} !important;
    color: ${({theme:{colors}}) => colors['thickBlack']} !important;
    opacity: 1 !important;
  }
`

const InputCounter = ({ counter=0, borderRequired, onChange, updateCounter, inputWidth, height, mobileHeight, mobileWidth, mobileInputSize, mobileFontSize, mobileInputIncrementBorder, mobileInputDecrementBorder, hideLRInputBorder, ...props }) => {

  return (
    <Flex>
      <InputDecrement disabled={props.disableDecrement} onClick={(event) => updateCounter(event, parseInt(counter)-1)} mobileHeight={mobileHeight} mobileWidth={mobileWidth} mobileFontSize={mobileFontSize} mobileInputDecrementBorder={mobileInputDecrementBorder} borderRequired={borderRequired} {...props} height={height}>–</InputDecrement>
      <InputNumber inputWidth={inputWidth} height={height} mobileHeight={mobileHeight} mobileWidth={mobileWidth} mobileInputSize={mobileInputSize} hideLRInputBorder={hideLRInputBorder} id="inputNumber" borderRequired={borderRequired} min="0" value={counter} {...props} onChange={onChange} disabled={props.disableInput} />
      <InputIncrement type='primary' disabled={props.disableIncrement} mobileHeight={mobileHeight} mobileWidth={mobileWidth} mobileFontSize={mobileFontSize} mobileInputIncrementBorder={mobileInputIncrementBorder} onClick={(event) => updateCounter(event, parseInt(counter)+1)} borderRequired={borderRequired} {...props} height={height}>+</InputIncrement>
    </Flex>
  )
}

InputCounter.defaultProps = {
  incrementBgColor: 'lightestGreyNew',
  decrementBgColor: 'lightestGreyNew',
  incrementOperatorColor: 'operatorColor',
  decrementOperatorColor: 'operatorColor',
  inputNumberBg: 'white',
  borderRequired: true,
  hideLRInputBorder: false,
  disableInput: false
}

export default InputCounter;