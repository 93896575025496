import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const CardText = styled(Box).attrs(({ block }) => ({as: 'p', block}))`
	color: ${props => props.color ? props.theme.colors[props.color] : props.theme.body};
  margin-top: ${props => props.mt};
  white-space: ${props => props.whiteSpace};
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize] || '0.875rem'};
  font-family: ${props => props.fontFamily ? `Figtree-${props.fontFamily}` : 'Figtree-Medium'};
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  letter-spacing: ${props => props.letterSpacing || '0.01071em'};
  margin: 0;
  display: ${({ block }) => block ? 'block' : 'inline'};
  margin-bottom: ${props => props.mb || props.my ||'0.35em'};
  text-align: ${({ textAlign }) => textAlign};
  margin-right: ${props => props.mr || props.mx || 0};
  padding-bottom: ${props => props.pb || props.py || '0.35em'};
  padding-left: ${props => props.pl || props.px || 0};
  padding-right: ${props => props.pr || props.px || 0};
  padding-top: ${props => props.pt || props.py || 0};
  margin-left: ${props => props.ml || props.mx || 0};
  ${({ cursor }) => cursor && css`
    cursor: ${cursor};
  `}
`

CardText.defaultProps = {
  block: true
}

export default CardText
