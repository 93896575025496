import React, { Suspense, lazy } from 'react';
import { Image, CardTitle, Button } from '../components/atoms';
import { Link } from 'react-router-dom';
import { Text } from '../components/atoms/typography';
import {PageHeader} from '../components/molecules/buildingblocks';
import useRequestPermissions from '../utils/customHooks/useRequestPermissions';
const Uploader = lazy(() => import('../components/molecules/Uploader'));

const EmptyPage = ({ image, header, body, clickToAction, dataTestId, eventName, uploadButton, noMargin, headerBar, pl, height='80vh', ...props }) => {

  const { permissionsState, isPermissionDenied, isPermissionGranted, requestNativePermissions } = useRequestPermissions(['storage', 'camera'], () => {});

  return (
    <div className='flex flex-col'>
      {
        headerBar && 
        <PageHeader title={headerBar?.text} onClick={headerBar.onClick} fontSize={'lg'} iconNeeded={true} modal={false} pl={pl} borderBottom={true}/>
      }
      {uploadButton ? 
        <>
          <div className='flex flex-col mx-12 items-center justify-center mt-5 mb-5'>
            { image ? <Image className='min-h-52' src={image.src} minWidth={'300px'} width={'30%'}/> : null } 
            { header ? <Text textAlign='center' fontSize={['mdL', 'xl']} mt={6} color='extraInformation' letterSpacing='0px' bolder>{header.text}</Text> : null }
            { body ? <Text mt={'12px'} width={['100%', '80%']} lineHeight='22px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.text}</Text> : null }
            <div className='text-center mt-8' style={{maxWidth:'200px'}}>
              <Suspense fallback={'Loading...'}>
                <Uploader isPermissionDenied={isPermissionDenied} isPermissionGranted={isPermissionGranted} permissionsState={permissionsState} requestNativePermissions={requestNativePermissions}background={'primary'} isButton={true} btnSize='mdL' br={'3px'} btnType='primary' isAssigned={false} customerToken={props.customerToken} updateFiles={props.updateFiles} type={props.type} onSuccess={props.onFileUploadSuccess} onFailure={props.onFileUploadFailure}>
                  <CardTitle mb='0px' pb='0px' bold color='white' data-event-name='mr_add_reports' data-event-data={JSON.stringify({'statux':'gallery'})} fontSize='md'>{props.btnText}</CardTitle>
                </Uploader>
              </Suspense>
            </div>
          </div>
        </>
        :
        <div className='flex flex-col mx-12 items-center justify-center' style={{ height: height }} data-testid={dataTestId}>
          {image ? <Image className='min-h-52' src={image.src} height={'auto'} minWidth={'300px'} width={image.width ? image.width : ['80%', '500px']}/> : null}
          {header ? <Text fontSize={['mdL', 'xl']} textAlign='center' mt={noMargin ? -5 : 6 } color='extraInformation' letterSpacing='0px' bolder>{header.text}</Text> : null}
          {body ?
            <>
              <Text justifyContent='center' mt={['8px', '12px']} width={body?.width ? body.width : ['100%', '110%']} lineHeight='24px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.text}</Text>
              {body.subText ? <Text justifyContent='center' mt={5} width={['100%', '80%']} lineHeight='24px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.subText}</Text> : null }
            </>
            : null}
          {clickToAction && !clickToAction.to && clickToAction.onClick ? <Button mt={6} onClick={clickToAction.onClick} size={'mdL'} fontSize='md' letterSpacing='0' br='6px' data-event-name={eventName} type='primary'>{clickToAction.text}</Button> : null}
          {clickToAction && !clickToAction.onClick && clickToAction.to ? <Button mt={6} to={clickToAction.to} as={Link} size={'mdL'} fontSize='md' letterSpacing='0' br='6px' data-event-name={eventName} type='primary'>{clickToAction.text}</Button> : null}
        </div>}
    </div>
  )
}

export default EmptyPage;