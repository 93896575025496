import React, { useState, useEffect, forwardRef } from 'react'
import styled, {css} from 'styled-components';
import Input from '../atoms/Input';
import { validateMobileNumber } from '../../utils/common'
import Text from '../atoms/typography/Text';
import { Flex } from 'rebass';  

const ExtendedInput = styled(Input)`
  ${({valid}) => valid == false && css`
    border-color: red;
  `};
  background-color: ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.backgroundColor};
  display : flex;
  flex-grow : 2;
  letter-spacing: 0.8px;
  font-size: ${({ theme: { fontSizes }, fontSize}) => fontSize ? fontSizes[fontSize] : fontSizes.md};
  color: ${({ theme: { colors }, disabled}) => disabled ? colors.disabledText : colors.extraInformation};
  font-family: "Figtree-SemiBold";  
  height: ${props => props.height};
  font-family: Figtree-SemiBold;
  ` 

const MobileNumber = ({disabled, placeholder, value, onChange, onClick, maxLength, ...props }) => {

  const {mb, mr, ml, mt, p, fontSize, backgroundColor } = props
  let currentValue = value?.replace(/\D/g,'')?.slice(0,10)
  const [valid, setValid] = useState(true)
  useEffect(() => {
    currentValue = value?.replace(/\D/g,'')?.slice(0,10)
    setValid(currentValue?.length == 10 ? validateMobileNumber(currentValue) : valid )
  }, [onChange, value])

  return (
    <Flex width={props.width} flexDirection='column'>
      <ExtendedInput ref={props.focusRef} onClick={onClick} height={props.height} disabled={disabled} p={p} mb={mb} mr={mr} ml={ml} mt={mt} backgroundColor={backgroundColor} fontSize={fontSize} maxLength={maxLength} valid={valid} onBlur={() => setValid(validateMobileNumber(currentValue))} onFocus={() => setValid(true)} placeholder={placeholder} data-testid='enter-coupon' value={value?.replace(/\D/g,'')?.slice(0,10)} onChange={onChange} />
      { !valid && <Text color='red' mt={'6px'} ml={ml} fontSize='sm'>Please enter a valid number</Text>}
    </Flex>
  )
}

MobileNumber.defaultProps = {
  valid: true,
  height: '54px',
  onClick: null
}

export default forwardRef(MobileNumber);