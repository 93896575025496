import styled, { css }from 'styled-components';
import React, { useState } from 'react';
import { Flex } from 'rebass';

const setPosition = (position) => {
  switch(position){
  case 'top':
    return css`
				bottom: 100%;
				left: 50%;
				padding-bottom: 8px;
				transform: translateX(-50%);
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}`
  case 'top-left':
    return css`
				bottom: 100%;
				left: 0;
				padding-bottom: 8px;
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};
					bottom: 0;
					left: 10%;
				}`
  case 'top-right':
    return css`
				bottom: 100%;
				right: 0;
				padding-bottom: 8px;
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};
					bottom: 0;
					right: 10%;
				}`				
  case 'bottom':
    return css`
				top: 100%;
				left: 50%;
				padding-top: 8px;
				transform: translateX(-50%);
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}`
  case 'bottom-left':
    return css`
				top: 100%;
				padding-top: 8px;
				left: 0;
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};;
					top: 0;
					left: 8%;
				}`
  case 'bottom-right':
    return css`
				top: 100%;
				right: 0;
				padding-top: 8px;
				&::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid ${props => props.theme.colors[props.borderColor]};
					top: 0;
					right: ${props => props.right || '8%'};
				}`
  case 'left':
    return css`
				top: 50%;
				right: 100%;
				padding-right: 8px;
				transform: translateY(-50%);
				&::after {
					border-left: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}`
  case 'right':
  default:
    return css`
			top: 50%;
			left: 100%;
			padding-left: 8px;
			transform: translateY(-50%);
			&::after {
				border-right: 8px solid ${props => props.theme.colors[props.borderColor] || 'rgba(0,0,0,.7)'};
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}`
  }		
}

const ToolTipMessage = styled.div`
  background: ${props => props.color ? props.theme.colors[props.color] : '#4C4C4C' };
  border-radius: 3px;
  color: ${props => props.fontColor || '#FFFFFF'};
  font-size:  ${props => props.fontSize || `0.75rem` };
	font-family: ${props => props.fontFamily ? `Figtree-${props.fontFamily}` : 'Figtree-Medium'}
  line-height: 1.4;
  padding: .75em;
  text-align: center;
	box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.09);
	border-radius: ${props => props.borderRadius || '0'};
	padding-right: ${props => props.pr || '0'};
	padding-left: ${props => props.pl || '0'};
	${({ pointer }) => pointer &&
    css`
      cursor: pointer;
    `
}
`


const ToolTipMessageWrapper = styled(Flex)`
 min-width: ${props => props.minWidth || '300px'};
  position: absolute;
  z-index: ${props => props.zIndex};
	display: ${({ visible }) => visible ? 'unset' : 'none'};
  &::after {
    content: '';
    position: absolute;
  }
  ${({ position }) => setPosition(position)}
`

const ToolTipWrapper = styled(Flex)`
	position: relative;
`
const ToolTip = ({children, message, position, show, noHover, color, fontSize, fontFamily, minWidth, borderColor, fontColor, pr, pl, right, borderRadius, onClick, onMessageClick, pointer, zIndex = '10'}) => {
  const [showToolTip, toggleShowToolTip] = useState(false)
  return(
    <ToolTipWrapper onClick={onClick} onMouseLeave={noHover ? null : () => toggleShowToolTip(false)} onMouseOver={noHover ? null : () => { if(message) toggleShowToolTip(true)}}>
      {children}
      <ToolTipMessageWrapper zIndex={zIndex} right={right} borderColor={borderColor} minWidth={minWidth} visible={typeof(show) === 'boolean' ? show : showToolTip} position={position}>
        <ToolTipMessage borderRadius={borderRadius} pl={pl} pr={pr} fontColor={fontColor} color={color} fontSize={fontSize} fontFamily={fontFamily} onClick={onMessageClick} pointer={pointer} >
          {message}
        </ToolTipMessage>
      </ToolTipMessageWrapper>
    </ToolTipWrapper>
  )	
}

ToolTip.defaultProps = {
  noHover: false,
  onMessageClick: () => {}
}

export default ToolTip