import styled from 'styled-components';
import { Link } from 'react-router-dom';

//Todo: Add disabled style later if needed
const CardLink = styled(Link)`
	color: ${props => props.theme.colors.blue};
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.bold ? 'Figtree-SemiBold' : 'Figtree-Medium'};
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize] || '1em'};
  margin-right:  ${props => props.mr || '0px'};
`

export default CardLink;