import React, { useEffect, useState } from 'react'
import newAppDownload from '../../assets/icons/new-app-download.svg'
import { Flex } from 'rebass'
import { Button, Icon } from '../atoms'
import { Text } from '../atoms/typography'
import { openAppStore } from '../../utils/common'

const DownloadApp = () => {

  const [appName, setAppName] = useState('ekincare')

  useEffect(() => {
    const hostname = window.location.host
    setAppName(hostname === 'healthcore360.ekincare.com' ? 'Healthcore360' : 'ekincare')
  }, [])

  return (
    <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'} my={'68px'}>
      <Icon src={newAppDownload} alt='App Download Icon' width={'194px'} height={'194px'} mt={'24px'} mb={'48px'} />
      <Text color='extraInformation' fontSize='lg' lineHeight='24px' mb={'24px'} bolder>Reports Available on app!</Text>
      <Text color='darkestGreyNew' fontSize='md' lineHeight='24px' textAlign={'center'} mx={'32px'} mb={'48px'}>View your latest health checkup reports and doctor prescriptions securely on the {appName} app</Text>
      <Button type={'primary'} borderRadius='6px' width='218px' height='44px' onClick={openAppStore}>Download {appName} app</Button>
    </Flex>
  )
}

export default DownloadApp